<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="invoiceModal" persistent max-width="500">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="overline">Faturar Compra</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="closeInvoiceModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="payed.accountId"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Conta"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="payed.email"
                    label="Usuário"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="payed.password"
                    label="Senha"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn dark color="green" width="100%" @click="saleInvoice">
                    FATURAR
                  </v-btn>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-btn
                    dark
                    color="red"
                    width="100%"
                    @click="closeInvoiceModal"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/base/stock-transactions/create"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Nova Transação</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                to="/base/stock-transactions/create"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="sales"
          :search="search"
        >
          <template v-slot:[`item.amountLiquid`]="{ item }">
            <span>R$ {{ formatPrice(item.amountLiquid) }}</span>
          </template>

          <template v-slot:[`item.supplier`]="{ item }">
            <span>{{
              item.supplier
                ? item.supplier.person.corporateName
                : "TRANSAÇÃO INTERNA"
            }}</span>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ dateFormat(item.createdAt) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small :to="`/base/stock-transactions/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
            <v-btn
              v-if="item.status !== 'FATURADO'"
              small
              outlined
              color="success"
              @click="openInvoiceModal(item)"
              >Faturar</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import moment from "moment";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Trnsações de Estoque",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "digitech_plus.users.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Produtos",
            to: "/base/products",
            permission: "immobile_tech.immobile.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Fornecedores",
            to: "/base/suppliers",
            permission: "immobile_tech.immobile.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Vendas",
        disabled: true,
        href: "/base/sales",
      },
    ],
    deleteDialog: false,
    sales: [],
    accounts: [],
    search: "",
    loading: false,
    transactionId: "",
    invoiceModal: false,
    transaction: null,
    payed: {
      accountId: "",
      email: "",
      password: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data",
        align: "start",
        value: "createdAt",
        filtering: true,
      },
      {
        text: "Fornecedor",
        align: "start",
        value: "supplier",
        filtering: true,
      },
      {
        text: "Status",
        value: "status",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountLiquid",
        filtering: true,
      },
      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },
    openInvoiceModal(item) {
      this.transaction = item;
      this.transactionId = item.id;
      this.invoiceModal = true;
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    closeInvoiceModal() {
      this.payed.email = "";
      this.payed.password = "";
      this.payed.accountId = "";
      this.transaction = null;
      this.transactionId = null;
      this.invoiceModal = false;
    },
    saleInvoice() {
      http
        .put(
          `base/stock-transactions/${this.transactionId}/invoice`,
          this.payed
        )
        .then(() => {
          this.getItems();
        });
    },

    deleteOpen(itemId) {
      this.transactionId = itemId;
      this.deleteDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("base/stock-transactions").then(
        (data) => {
          this.sales = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`base/stock-transactions/${this.transactionId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  mounted() {
    this.getItems();
    this.getAccounts();
  },
});
</script>

